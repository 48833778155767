import { DigitalIdApi } from './DigitalIdApi'
import { IpiaApi } from './IpiaApi'
import { TextApi } from './TextApi'

export const apiReducers = {
  [DigitalIdApi.reducerPath]: DigitalIdApi.reducer,
  [IpiaApi.reducerPath]: IpiaApi.reducer,
  [TextApi.reducerPath]: TextApi.reducer
}

export const apiMiddleware = [DigitalIdApi.middleware, IpiaApi.middleware, TextApi.middleware]
