import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppTexts } from 'api/TextApi'

export interface AppConfigState {
  appName: string
  appDescription: string
  appContact: string
  appTheme: string
  googleAnalyticsId: string
  appText: AppTexts
}

const initialState: AppConfigState = {
  appName: 'IPIA Acknowledgement ',
  appDescription: 'An MIT Atlas app.',
  appContact: 'help@mit.edu',
  appTheme: 'medical',
  googleAnalyticsId: 'g-xxxxxxxx',
  appText: {}
}

// https://redux-toolkit.js.org/tutorials/quick-start#create-a-redux-state-slice
export const appConfigSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setAppText: (state, action: PayloadAction<AppTexts>) => {
      state.appText = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions

// Action creators are generated for each case reducer function
export const { setAppText } = appConfigSlice.actions

export default appConfigSlice.reducer
